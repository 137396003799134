.neetphysicsnaveen-section {
    width: 100%;
    /* background-color: rgb(247, 250, 252); */
    background-color: rgb(255, 250, 250);
}

.neetphysicsnaveen-section .container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 40px 60px;
}

.neetphysicsnaveen-section .container .heading {
    width: 100%;
    /* text-align: center; */

}

.neetphysicsnaveen-section .container .heading span {
    /* color: #1B9BFF; */
    /* color: cadetblue; */
    color: #2E3192;
    font-size: 35px;
    font-variant: small-caps;
    font-weight: 600;
    position: relative;
}

.neetphysicsnaveen-section .container .course-detail {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 630px; */


    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: hidden;
}

.neetphysicsnaveen-section .container .course-detail .image {
    width: 40%;

}

.neetphysicsnaveen-section .container .course-detail .image img {
    object-fit: cover;
    width: 100%;
}


.neetphysicsnaveen-section .container .course-detail .detail {
    width: 60%;
    padding: 0px 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.neetphysicsnaveen-section .container .course-detail .detail h2 {
    font-size: 23px;
    font-weight: 600;
    font-variant: small-caps;
}

.neetphysicsnaveen-section .container .course-detail .detail p {
    /* color: rgb(115, 115, 115); */
    color: black;
}





.button-86 {
    /* margin-top: 5px; */
    all: unset;
    width: 100px;
    height: 25px;
    font-size: 16px;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-86::after,
.button-86::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
}

.button-86::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    /* background: #28282d; */
    /* background-color: cadetblue; */
    background-color: #2E3192;
    border-radius: 10px;
}

.button-86::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 20px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
}

.button-86:hover::before {
    transform: translate(5%, 20%);
    width: 105%;
    height: 105%;
}

.button-86:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
}

.button-86:active::after {
    transition: 0s;
    transform: translate(0, 5%);
}

@media (max-width:900px) {
    .neetphysicsnaveen-section .container .course-detail {
        flex-direction: column;
        gap: 25px;
        height: auto;
    }

    .neetphysicsnaveen-section .container .course-detail .image {
        width: 100%;
    }

    .neetphysicsnaveen-section .container .course-detail .detail {
        width: 100%;
    }
}

@media (max-width:695px) {

    .neetphysicsnaveen-section .container .heading {

        text-align: center;
    }

    .neetphysicsnaveen-section .container .heading span {
        font-size: 25px;
    }
}

@media (max-width:538px) {
    .neetphysicsnaveen-section .container {
        padding: 40px 20px;
    }
}

@media (max-width:470px) {

    .neetphysicsnaveen-section .container .heading span {
        font-size: 20px;
        font-weight: 500;
    }
}

@media (max-width:365px) {

    .neetphysicsnaveen-section .container .heading span {
        font-size: 18px;
    }
}