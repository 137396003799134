.mySwiper{
    width: 100%;
    height: 400px;
}

.mySwiper .bannerImage{
    object-fit: fill;
    height: 100%;
    width: 100%;
}

@media (max-width:800px) {
    .mySwiper{
        width: 100%;
        height: 300px;
    }
}

@media (max-width:560px) {
    .mySwiper{
        width: 100%;
        height: 250px;
    }
}

@media (max-width:430px) {
    .mySwiper{
        width: 100%;
        height: 230px;
    }
}