.testimonial-section{
    width: 100%;
}

.testimonial-section .container{
    max-width: 1920px;
    margin: 0 auto;
}

.testimonial-section .container .bg{
    width: 100%;
    height: 380px;
    background: url(./bg.jpg) no-repeat center;
    background-size: cover;
}

.testimonial-section .container .main-testimonial-section{
    padding: 40px 60px;
}

.testimonial-section .container .main-testimonial-section .heading{
    width: 100%;
    text-align: center;
}

.testimonial-section .container .main-testimonial-section .heading span{
    font-size: 38px;
    font-weight: 700;
    font-variant: small-caps;
    position: relative;
    /* color: cadetblue; */
    color: #2E3192;
}

.testimonial-section .container .main-testimonial-section .testimonial-container{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .left{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 19px;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right{
    width: 28%;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right .input-search-box{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right .input-search-box span{
    font-size: 20px;
    /* color: cadetblue; */
    color: #2E3192;
    font-weight: 600;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right .input-search-box .input-search-content{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right .input-search-box .input-search-content input{
    width: 80%;
    height: 100%;
    outline: none;
    border: 1px solid #2E3192;
}

.testimonial-section .container .main-testimonial-section .testimonial-container .right .input-search-box .input-search-content .btn{
    width: 20%;
    height: 100%;
    /* background-color: cadetblue; */
    background-color: #2E3192;
    color: white;
}

@media (max-width:800px) {

    .testimonial-section .container .main-testimonial-section{
        padding: 40px 30px;
    }
    
}

@media (max-width:760px) {

    .testimonial-section .container .main-testimonial-section .testimonial-container{
        flex-direction: column;
        gap: 30px;
    }
    
    .testimonial-section .container .main-testimonial-section .testimonial-container .left{
        width: 100%;
    }
    
    .testimonial-section .container .main-testimonial-section .testimonial-container .right{
        width: 40%;
    }
    
}

@media (max-width:528px) {
    .testimonial-section .container .main-testimonial-section .testimonial-container .right{
        width: 80%;
    }
}

@media (max-width:344px) {
    .testimonial-section .container .main-testimonial-section .testimonial-container .right{
        width: 100%;
    }
}

@media (max-width:570px) {
    .testimonial-section .container .bg {
        width: 100%;
        height: 270px;
        background: url(./bg.jpg) no-repeat center;
        background-size: cover;
    }
}