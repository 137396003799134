.doubts-section{
    width: 100%;
}

.doubts-section .container{
    max-width: 1920px;
    margin: 0 auto;
}

.doubts-section .container .bg{
    width: 100%;
    height: 380px;
    background: url(./bg.jpg) no-repeat center;
    background-size: cover;
}

.doubts-section .container .doubts-main-container{
    padding: 40px 60px;
}

.doubts-section .container .doubts-main-container .heading{
    width: 100%;
    text-align: center;
}

.doubts-section .container .doubts-main-container .heading span{
    font-size: 38px;
    font-weight: 700;
    font-variant: small-caps;
    position: relative;
    /* color: cadetblue; */
    color: #2E3192;
}

.doubts-section .container .doubts-main-container .doubt-main-box{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.doubts-section .container .doubts-main-container .doubt-main-box .left{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.doubts-section .container .doubts-main-container .doubt-main-box .right{
    width: 28%;
}

.doubts-section .container .doubts-main-container .doubt-main-box .right .input-search-box{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.doubts-section .container .doubts-main-container .doubt-main-box .input-search-box span{
    font-size: 20px;
    /* color: cadetblue; */
    color: #2E3192;
    font-weight: 600;
}

.doubts-section .container .doubts-main-container .doubt-main-box .input-search-box .input-search-content{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doubts-section .container .doubts-main-container .doubt-main-box .input-search-box .input-search-content input{
    width: 80%;
    height: 100%;
    outline: none;
    border: 1px solid #2E3192;
}

.doubts-section .container .doubts-main-container .doubt-main-box .input-search-box .input-search-content .btn{
    width: 20%;
    height: 100%;
    /* background-color: cadetblue; */
    background-color: #2E3192;
    color: white;
}

@media (max-width:800px) {
    
.doubts-section .container .doubts-main-container{
    padding: 40px 20px;
}
}

@media (max-width:800px) {
    .doubts-section .container .doubts-main-container .doubt-main-box{
        flex-direction: column;
        gap: 20px;
    }
    
    .doubts-section .container .doubts-main-container .doubt-main-box .left{
        width: 100%;
    }
    
    .doubts-section .container .doubts-main-container .doubt-main-box .right{
        width: 50%;
    }
}

@media (max-width:530px) {

    .doubts-section .container .doubts-main-container .doubt-main-box .right{
        width: 100%;
    }
}

@media (max-width:570px) {
    .doubts-section .container .bg {
        width: 100%;
        height: 270px;
        background: url(./bg.jpg) no-repeat center;
        background-size: cover;
    }
}