.offline-section {
    width: 100%;
}

.offline-section .container {
    max-width: 1920px;
    margin: 0 auto;
}

.offline-section .container .bg {
    width: 100%;
    height: 380px;
    background: url(./bg.jpg) no-repeat center;
    background-size: cover;
}

.offline-section .container .offline-main-container {
    padding: 40px 60px;
}

.offline-section .container .offline-main-container .heading {
    width: 100%;
    text-align: center;
}

.offline-section .container .offline-main-container .heading span {
    font-size: 38px;
    font-weight: 700;
    font-variant: small-caps;
    position: relative;
    /* color: cadetblue; */
    color: #2E3192;
}

.offline-section .container .offline-main-container .offline-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* .offline-section .container .offline-main-container .offline-container .up{
    display: flex;
    align-items: center;
    justify-content: center;
} */

table,
td,
th {
    border: 1px solid #ddd;
    text-align: left;
    border-collapse: collapse;
    /* width: 100%; */
}



th,
td {
    padding: 15px;
}


.offline-section .container .offline-main-container .down{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* color: rgb(89, 89, 89); */
    color: black;
}

@media (max-width:800px) {
    .offline-section .container .offline-main-container {
        padding: 40px 20px;
    }

    table {
        width: 700px;
        overflow: scroll;
    }

    .offline-section .container .offline-main-container .offline-container {
        overflow: scroll;
    }
}

@media (max-width:570px) {
    .offline-section .container .bg {
        width: 100%;
        height: 270px;
        background: url(./bg.jpg) no-repeat center;
        background-size: cover;
    }
}