.neet-section{
    width: 100%;
}

.neet-section .container{
    max-width: 1920px;
    margin: 0 auto;
}

.neet-section .container .bg{
    width: 100%;
    height: 380px;
    background: url(./bg.jpg) no-repeat center;
    background-size: cover;
    
}

.neet-section .container .neet-main-container{
    padding: 40px 60px;
}

.neet-section .container .neet-main-container .heading{
    width: 100%;
    text-align: center;
}

.neet-section .container .neet-main-container .heading span{
    font-size: 38px;
    font-weight: 700;
    font-variant: small-caps;
    position: relative;
    /* color: cadetblue; */
    color: #2E3192;
}

.neet-section .container .neet-main-container .neet-container-box{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

.neet-section .container .neet-main-container .neet-container-box .left{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.neet-section .container .neet-main-container .neet-container-box .left .up{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.neet-section .container .neet-main-container .neet-container-box .left .down{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.neet-section .container .neet-main-container .neet-container-box .left .down h2{
    font-size: 18px;
    font-weight: 700;
}

.neet-section .container .neet-main-container .neet-container-box .right{
    width: 35%;
    
}



.neet-section .container .neet-main-container .neet-container-box .right .main{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.neet-section .container .neet-main-container .neet-container-box .right h2{
    text-align: center;
    font-size: 20px;
    font-size: 600;
}

.neet-section .container .neet-main-container .neet-container-box .right form{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.neet-section .container .neet-main-container .neet-container-box .right .same-field{
    width: 100%;
    height: 40px;
}
.neet-section .container .neet-main-container .neet-container-box .right .textarea{
    width: 100%;
    height: 100px;
}
.neet-section .container .neet-main-container .neet-container-box .right .textarea textarea{
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid rgb(182, 180, 180);
    border-radius: 10px;
    padding-left: 10px;
}

.neet-section .container .neet-main-container .neet-container-box .right .same-field input{
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid rgb(182, 180, 180);
    border-radius: 10px;
    padding-left: 10px;
}

@media (max-width:940px) {
    .neet-section .container .neet-main-container .neet-container-box{
        flex-direction: column;
        gap: 25px;
    }

    .neet-section .container .neet-main-container .neet-container-box .left{
        width: 100%;
    }

    .neet-section .container .neet-main-container .neet-container-box .right{
        width: 100%;
    }
}

@media (max-width:500px) {
    .neet-section .container .neet-main-container{
        padding: 40px 20px;
    }
    
}


@media (max-width:570px) {
    .neet-section .container .bg {
        width: 100%;
        height: 270px;
        background: url(./bg.jpg) no-repeat center;
        background-size: cover;
    }
}