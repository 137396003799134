footer {
    /* position: fixed; */
    /* background: #0e64a5; */
    /* background-color: cadetblue; */
    /* background-color: #7377f4; */
    background-color: #2E3192;
    width: 100%;
    bottom: 0;
    left: 0;
}

/* footer::before{
    content: '';
    position: absolute;
    left: 0;
    top: 100px;
    height: 1px;
    width: 100%;
    background: #AFAFB6;
  } */
footer .content {
    max-width: 1250px;
    margin: auto;
    padding: 30px 40px 40px 40px;
}

footer .content .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.content .top .logo-details {
    color: #fff;
    font-size: 30px;
}

.content .top .logo-details span img {
    width: 100px;
    height: 60px;
    object-fit: contain;
}

.content .top .media-icons {
    display: flex;
}

.content .top .media-icons a {
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    transition: all 0.4s ease;
}

.top .media-icons a:nth-child(1) {
    background: #4267B2;
}

.top .media-icons a:nth-child(1):hover {
    color: #4267B2;
    background: #fff;
}

.top .media-icons a:nth-child(2) {
    background: #1DA1F2;
}

.top .media-icons a:nth-child(2):hover {
    color: #1DA1F2;
    background: #fff;
}

.top .media-icons a:nth-child(3) {
    background: #E1306C;
}

.top .media-icons a:nth-child(3):hover {
    color: #E1306C;
    background: #fff;
}

.top .media-icons a:nth-child(4) {
    background: #0077B5;
}

.top .media-icons a:nth-child(4):hover {
    color: #0077B5;
    background: #fff;
}

.top .media-icons a:nth-child(5) {
    background: #FF0000;
}

.top .media-icons a:nth-child(5):hover {
    color: #FF0000;
    background: #fff;
}

footer .content .midle-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

footer .content .midle-section .box {
    width: calc(100% / 5 - 10px);
    /* width: 24%; */
}

/* footer .content .midle-section .box-1 p{
    width: 80%;
} */

.content .midle-section .box .link_name {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
}

.midle-section .box .link_name::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 35px;
    background: #fff;
}

.content .midle-section .box li {
    margin: 6px 0;
    list-style: none;
}

.content .midle-section .box li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease
}

.content .midle-section .box li a:hover {
    opacity: 1;
    text-decoration: underline;
}

.content .midle-section .input-box {
    margin-right: 55px;
}

.midle-section .input-box input {
    height: 40px;
    width: calc(100% + 55px);
    outline: none;
    border: none;
    background: white;
    outline: none;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    color: black;
    margin-top: 5px;
}

.midle-section .input-box input::placeholder {
    color: #AFAFB6;
    font-size: 16px;
}

.midle-section .input-box input[type="button"] {
    background-color: #6884f1;
    /* color: #140B5C; */
    /* color: white; */
    color: black;
    border: 1px solid white !important;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin: 4px 0;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.4s ease;
}

.input-box input[type="button"]:hover {
    opacity: 1;
}

footer .bottom-details {
    width: 100%;
    /* background: #0F0844; */
    /* background-color: rgb(59, 123, 125); */
    /* background-color: #2E3192; */
    background-color: black;
}

footer .bottom-details .bottom_text {
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
}

.bottom-details .bottom_text a:hover {
    opacity: 1;
    text-decoration: underline;
}

.bottom-details .bottom_text a {
    margin-right: 10px;
}

@media (max-width: 900px) {
    footer .content .midle-section {
        flex-wrap: wrap;
    }

    footer .content .midle-section .input-box {
        width: 40%;
        margin-top: 10px;
    }
}

@media (max-width: 700px) {
    footer {
        position: relative;
    }

    .content .top .logo-details {
        font-size: 26px;
    }

    .content .top .media-icons a {
        height: 35px;
        width: 35px;
        font-size: 14px;
        line-height: 35px;
    }

    footer .content .midle-section .box {
        width: calc(100% / 3 - 10px);
    }

    footer .content .midle-section .input-box {
        width: 60%;
    }

    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a {
        font-size: 12px;
    }
}

@media (max-width: 520px) {
    footer::before {
        top: 145px;
    }

    footer .content .top {
        flex-direction: column;
    }

    .content .top .media-icons {
        margin-top: 16px;
    }

    footer .content .midle-section .box {
        width: calc(100% / 2 - 10px);
    }

    footer .content .midle-section .input-box {
        width: 100%;
    }
}