.query-section {
    width: 100%;
}

.query-section .container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 40px 60px;

    display: flex;
    justify-content: space-between;
}

.query-section .container .left {
    width: 47%;
    height: 480px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.query-section .container .left .heading{
    width: 100%;
    text-align: center;
    height: 10%;
}

.query-section .container .left .heading span{
    font-size: 30px;
    font-weight: 600;
    font-variant: small-caps;
    /* color: cadetblue; */
    color: #2E3192;
}

.query-section .container .right {
    width: 47%;
    height: 480px;
    padding: 15px 20px;

    display: flex;
    flex-direction: column;
    gap: 15px;


    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.query-section .container .right .right-heading {
    width: 100%;
    text-align: center;
}

.query-section .container .right .right-heading span {
    font-size: 30px;
    font-weight: 600;
    font-variant: small-caps;
    /* color: cadetblue; */
    color: #2E3192;
}

.query-section .container .right form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.query-section .container .right form .same-form-detail {
    width: 100%;
    height: 40px;
}

.query-section .container .right form .same-form-detail input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid rgb(196, 195, 195);
    padding-left: 10px;
    border-radius: 10px;
}

.query-section .container .right form .textarea{
    height: 90px !important;
    width: 100%;
}

.query-section .container .right form .textarea textarea{
    width: 100%;
    height: 100%;
    border: 1px solid rgb(169, 169, 169);
    outline: none;
    padding-left: 10px;
    border-radius: 10px;
}

.faq-box{
    overflow: auto;
    height: 85%;
    background-color: rgb(237, 237, 237);
    border-radius: 10px;
}

.faq-content {
    flex: 1;
    padding: 20px;
    /* overflow: auto; */
  }
  
  .faq-content h2 {
    color: #ff6016;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  
  .faq-item {
    margin-bottom: 15px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #f9f9f9;
  }
  
  .question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 10px;
  }
  
  .answer {
    padding: 10px;
    display: none;
    color: rgb(155, 155, 155);
  }
  
  .faq-item.open .answer {
    display: block;
  }


@media (max-width:962px) {
    .query-section .container .left .heading span{
        font-size: 25px;
    }
    .query-section .container .right .right-heading span{
        font-size: 25px;
    }
}

@media (max-width:870px) {
    .query-section .container {
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }
    
    .query-section .container .left {
        width: 90%;
    }
    
    .query-section .container .right {
        width: 90%;
    }
}

@media (max-width:530px) {
    .query-section .container {
        padding: 40px 20px;
    }

    .query-section .container .left {
        width: 100%;
    }
    
    .query-section .container .right {
        width: 100%;
    }
}

@media (max-width:370px) {
    .query-section .container .left .heading span{
        font-size: 22px;
    }
    .query-section .container .right .right-heading span{
        font-size: 23px;
    }
}